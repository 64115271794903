<template>
  <nav
    class="navbar-menu fixed mb-0 h-auto z-20 top-0 lg:right-0 py-3 px-3"
    :style="`background: ${$route.meta.topColor || '#295DA4'};`"
  >
    <div class="w-full flex justify-between" style="position: relative">
      <button
        style="margin-top: 9px"
        class="nav-menu-button lg:hidden"
        aria-label="Desplegar menu"
        @click="$emit('collapse')"
      >
        <icon icon="bars" />
      </button>
      <div class="hidden md:block flex items-center">
        <!-- <h2
          v-if="$route.meta.pageTitle"
          class="my-auto text-3xl text-black"
          :class="{ 'pr-4 ': $route.meta.breadcrumb }"
        >
          {{ $route.meta.pageTitle }}
        </h2> -->

        <breadcrumb
          v-if="$route.meta.breadcrumb"
          :route="$route"
          :class="{ 'pl-4 ': $route.meta.pageTitle }"
        />
        <breadcrumb-dynamic
          v-if="$route.meta.breadcrumbDynamic"
          :route="$route"
          :class="{ 'pl-4 ': $route.meta.pageTitle }"
        />
      </div>

      <div class="flex">
        <!-- NOTIFICATIONS -->
        <div class="flex items-center">
          <t-dropdown
            text=""
            id="notifications"
            :classes="{
              dropdown:
                'w-80 bg-white left-auto right-0 top-4 mt-3 overflow-auto'
            }"
          >
            <div
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown
              }"
            >
              <button
                id="notifications-menu"
                class="mr-4 flex items-center transition duration-75 ease-in-out rounded-full text-black text-white text-xl focus:outline-none focus:shadow-solid"
                :class="{ ' ': isShown }"
                aria-label="Notificaciones"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <icon icon="bell" />
                <sup
                  v-if="countNotificationsUnreaded"
                  class="notification-indicator bg-red-50 text-red-700 border border-red-700 rounded-full -ml-2 -mt-2 font-bold text-xs"
                  :class="
                    countNotificationsUnreaded.toString().includes('K')
                      ? 'notification-indicator-xl'
                      : ''
                  "
                  >{{ countNotificationsUnreaded }}</sup
                >
              </button>
            </div>
            <div class="py-1 rounded-md shadow-xs" slot-scope="{ hide }">
              <Notifications :hide="hide" />
            </div>
          </t-dropdown>
        </div>
        <!-- END OF NOTIFICATIONS -->
        <div>
          <t-dropdown text="">
            <div
              class="user-info flex flex-items-center flex-items"
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown
              }"
            >
              <div
                class="my-auto text-right leading-tight hidden sm:block"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <p
                  class="font-semibold text-white text-sm"
                  style="cursor: pointer"
                >
                  {{ user.first_name }} {{ user.last_name }}
                </p>
              </div>
              <button
                id="user-menu"
                class="ml-2 flex items-center transition duration-75 ease-in-out bg-gray-300 rounded-full focus:outline-none focus:shadow-solid"
                :class="{ 'border-gray-300 bg-gray-500 text-white ': isShown }"
                aria-label="User menu"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <img
                  class="w-12 h-12 rounded-full object-cover"
                  :src="
                    user.image_profile
                      ? user.image_profile
                      : require('@/assets/img/profile/default.jpg')
                  "
                  alt=""
                />
              </button>
            </div>
            <div class="py-1 rounded-md shadow-xs">
              <a
                href="#"
                class="block px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                role="menuitem"
                @click="logout"
              >
                <icon icon="sign-out-alt" class="mr-2" />
                Cerrar sesión
              </a>
            </div>
          </t-dropdown>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Notifications from "../notifications/Notifications.vue"
import Searchbar from "@/components/Searchbar"
import Breadcrumb from "../breadcrumb/Breadcrumb"
import BreadcrumbDynamic from "../breadcrumb/BreadcrumbDynamic"

const notify = () => import("@/utils/notify.js")
export default {
  name: "navbar",
  components: {
    Notifications,
    Searchbar,
    Breadcrumb,
    BreadcrumbDynamic
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    countNotificationsUnreaded() {
      return this.$store.getters["auth/countNotificationsUnreaded"]
    }
  },

  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout", this.$acl.change)
        .then((response) => {
          notify().then(({ notification }) => {
            notification("logout", 200, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    canSee(url) {
      this.$acl.check(this.$store.state.auth.userRole)
      return this.$acl.check(this.$router.match(url).meta.rule)
    }
  }
}
</script>

<style lang="scss">
.navbar-menu {
  width: calc(100vw - 224px);
  margin-bottom: 67px;
}
@media (max-width: 1023px) {
  .navbar-menu {
    width: 100%;
  }
}

.notification-indicator {
  height: 25px;
  width: 25px;
  line-height: 25px;
}

.notification-indicator-xl {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
</style>
