<template>
  <div>
    <div class="bg-cream" style="min-height: 100vh">
      <div class="content-layout ml-0 lg:ml-54">
        <navbar-menu @collapse="collapseExpand" />

        <vertical-nav-menu
          :menuExpanded="menuExpanded"
          @closeMenu="collapseExpand"
        />

        <router-view
          class="main-view"
          id="main-view"
          :style="`background: linear-gradient(180deg, ${
            $route.meta.topColor || '#295DA4'
          } 45vh, #F6F9FA 45vh);`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VerticalNavMenu from "../components/vertical-nav-menu/VerticalNavMenu"
import NavbarMenu from "../components/navbar/NavBar"
import Breadcrumb from "../components/breadcrumb/Breadcrumb"
import BreadcrumbDynamic from "../components/breadcrumb/BreadcrumbDynamic"

function disableScrolling() {
  var x = window.scrollX
  var y = window.scrollY
  window.onscroll = function () {
    window.scrollTo(x, y)
  }
}

function enableScrolling() {
  window.onscroll = function () {}
}

const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      menuExpanded: false,
      titleName: null,
      isLoading: false,
      routeTitle: null
    }
  },
  components: {
    VerticalNavMenu,
    NavbarMenu,
    Breadcrumb,
    BreadcrumbDynamic
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    collapseExpand() {
      this.menuExpanded = !this.menuExpanded
    },
    getUserLogged() {
      this.$store.dispatch("auth/getUserLogged").catch((error) => {
        notify().then(({ notification }) => {
          notification("error", error.code, this.$snotify)
        })
      })
    }
  },
  created() {
    this.getUserLogged()

    // Set global theme
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)")
    mediaQueryList.addEventListener("change", (event) => {
      this.$store.dispatch("setTheme", event.matches ? "dark" : "light")
    })
    this.$store.dispatch("setTheme", mediaQueryList.matches ? "dark" : "light")

    // create an Observer instance
    const resizeObserver = new ResizeObserver((entries) => {
      const elementClassList = document.getElementById("main-view").classList
      if (entries[0].target.clientWidth < 1024) {
        if (elementClassList.contains("main-view-without-scroll"))
          elementClassList.remove("main-view-without-scroll")
        if (elementClassList.contains("main-view-with-scroll"))
          elementClassList.remove("main-view-with-scroll")
        return
      }
      if (entries[0].target.scrollHeight > entries[0].target.clientHeight) {
        if (elementClassList.contains("main-view-without-scroll"))
          elementClassList.remove("main-view-without-scroll")

        if (!elementClassList.contains("main-view-with-scroll"))
          elementClassList.add("main-view-with-scroll")
      } else {
        if (elementClassList.contains("main-view-with-scroll"))
          elementClassList.remove("main-view-with-scroll")
        if (!elementClassList.contains("main-view-without-scroll"))
          elementClassList.add("main-view-without-scroll")
      }
    })

    // start observing a DOM node
    resizeObserver.observe(document.body)
  },

  watch: {
    menuExpanded() {
      if (this.menuExpanded && window.matchMedia("(max-width: 992px)").matches)
        disableScrolling()
      else enableScrolling()
    }
  }
}
</script>
