<template>
  <div>
    <div
      class="vertical-nav-menu overflow-y-auto bg-white z-40 flex flex-col justify-between"
      :class="menuExpanded ? 'menu-show' : ''"
    >
      <div class="menu-content flex flex-col justify-between">
        <router-link to="/">
          <img
            class="px-5 w-1/2 mx-auto mt-2 mb-0"
            src="/static/lif-logo.png"
          />
        </router-link>

        <div>
          <hr class="mt-4 mx-5" />
          <p class="text-gray-600 text-center my-2 font-bold">ADMIN</p>
          <item
            v-for="item in adminPages"
            :key="item.id"
            :name="item.name"
            :url="item.url"
            :module="item.module"
            :icon="item.icon"
            :submenu="item.submenu"
            @click.native="functionMap(item.icon)"
            :isLink="true"
            :open="isGroupActive(item)"
          ></item>
          <hr class="mt-4 mx-5" />
          <p
            class="text-gray-600 text-center my-2 font-bold"
            v-if="canSee('reporter')"
          >
            NOTICIAS
          </p>
          <item
            v-for="item in newsPages"
            :key="item.id"
            :name="item.name"
            :url="item.url"
            :module="item.module"
            :icon="item.icon"
            :submenu="item.submenu"
            @click.native="functionMap(item.icon)"
            :isLink="true"
            :open="isGroupActive(item)"
          ></item>
          <hr class="mt-4 mx-5" />
          <p
            class="text-gray-600 text-center my-2 font-bold"
            v-if="canSee('scorer')"
          >
            ANOTACIONES
          </p>
          <item
            v-for="item in resultsPages"
            :key="item.id"
            :name="item.name"
            :url="item.url"
            :module="item.module"
            :icon="item.icon"
            :submenu="item.submenu"
            @click.native="functionMap(item.icon)"
            :isLink="true"
            :open="isGroupActive(item)"
          ></item>
        </div>
      </div>
      <img
        src="/static/ticktaps-logo.svg"
        class="w-1/2 mx-auto pb-3"
        alt="Logo Ticktaps"
      />
    </div>
    <div
      v-if="menuExpanded"
      class="lg:hidden bg-gray-700 opacity-50 h-screen w-full fixed top-15 left-0 z-20"
      @click="$emit('closeMenu')"
    ></div>

    <t-modal
      header="¿Estás seguro?"
      :value="confirmationModal"
      @closed="closeModal('confirmationModal')"
    >
      <div class="p-3">
        <p class="mb-1">¿Estás seguro que deseas cerrar la sesión?</p>
      </div>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            type="button"
            variant="outline-danger"
            @click="closeModal('confirmationModal')"
            class="mr-2"
          >
            No
          </t-button>
          <t-button type="button" @click="logout()">Cerrar sesión</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Item from "./VerticalNavMenuItem"
export default {
  data() {
    return {
      company: {
        logo: "@/assets/img/logo.png"
      },
      adminPages: [
        {
          id: 1,
          name: "Inicio",
          module: "dashboard",
          icon: "tv",
          url: "/dashboard"
        },
        {
          id: 2,
          name: "Usuarios",
          module: "users",
          icon: "users",
          url: "/usuarios"
        },
        {
          id: 3,
          name: "Torneos",
          module: "tournaments",
          icon: "trophy",
          url: "/torneos"
        },
        {
          id: 4,
          name: "Academias",
          module: "academies",
          icon: "shield-alt",
          url: "/academias"
        },
        {
          id: 5,
          name: "Jugadores",
          module: "players",
          icon: "users",
          url: "/jugadores"
        },
        {
          id: 6,
          name: "Entrenadores",
          module: "coaches",
          icon: "chalkboard-teacher",
          url: "/entrenadores"
        },
        {
          id: 7,
          name: "Categorías",
          module: "categories",
          icon: "chess",
          url: "/categorias"
        },
        {
          id: 8,
          name: "Subcategorías",
          module: "subcategories",
          icon: "chess",
          url: "/subcategorias"
        },
        {
          id: 9,
          name: "Títulos",
          module: "titles",
          icon: "flag-checkered",
          url: "/titulos"
        },

        {
          id: 10,
          name: "Partidos",
          module: "games",
          icon: "volleyball-ball",
          url: "/partidos"
        }
      ],
      newsPages: [
        {
          id: 11,
          name: "Noticias",
          module: "news",
          icon: "newspaper",
          url: "/noticias"
        }
      ],
      resultsPages: [
        {
          id: 12,
          name: "Resultados",
          module: "results",
          icon: "edit",
          url: "/resultados"
        }
      ],
      confirmationModal: false
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"] || {}
    },
    userRole() {
      return this.$store.getters["auth/getUserRole"]
    },
    isGroupActive() {
      return (sidebarItem) => {
        const path = this.$route.fullPath
        let open = false
        let func = function (sidebarItem) {
          if (sidebarItem.submenu) {
            sidebarItem.submenu.forEach((item) => {
              if (path == item.url) {
                open = true
              } else if (item.submenu) {
                func(item)
              }
            })
          }
        }
        func(sidebarItem)
        return open
      }
    }
  },
  props: {
    menuExpanded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Item
  },
  methods: {
    canSee(role) {
      return role === this.userRole || this.userRole === "admin"
    },
    functionMap(icon) {
      if (icon.includes("sign-out")) return this.logout()
      return this.$emit("closeMenu")
    },
    closeModal(modal) {
      this[modal] = false
    },
    logout() {
      if (!this.confirmationModal) {
        this.confirmationModal = true
        return
      }
      this.$store
        .dispatch("auth/logout", this.$acl.change)
        .then((response) => {
          this.$store.dispatch("test/resetTest")
          this.confirmationModal = false
          notify().then(({ notification }) => {
            notification("logout", 200, this.$snotify)
          })
        })
        .catch((error) => {
          this.confirmationModal = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {},
  watch: {
    $route() {}
  }
}
</script>
