var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar-menu fixed mb-0 h-auto z-20 top-0 lg:right-0 py-3 px-3",style:(("background: " + (_vm.$route.meta.topColor || '#295DA4') + ";"))},[_c('div',{staticClass:"w-full flex justify-between",staticStyle:{"position":"relative"}},[_c('button',{staticClass:"nav-menu-button lg:hidden",staticStyle:{"margin-top":"9px"},attrs:{"aria-label":"Desplegar menu"},on:{"click":function($event){return _vm.$emit('collapse')}}},[_c('icon',{attrs:{"icon":"bars"}})],1),_c('div',{staticClass:"hidden md:block flex items-center"},[(_vm.$route.meta.breadcrumb)?_c('breadcrumb',{class:{ 'pl-4 ': _vm.$route.meta.pageTitle },attrs:{"route":_vm.$route}}):_vm._e(),(_vm.$route.meta.breadcrumbDynamic)?_c('breadcrumb-dynamic',{class:{ 'pl-4 ': _vm.$route.meta.pageTitle },attrs:{"route":_vm.$route}}):_vm._e()],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex items-center"},[_c('t-dropdown',{attrs:{"text":"","id":"notifications","classes":{
            dropdown:
              'w-80 bg-white left-auto right-0 top-4 mt-3 overflow-auto'
          }},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var mousedownHandler = ref.mousedownHandler;
            var focusHandler = ref.focusHandler;
            var blurHandler = ref.blurHandler;
            var keydownHandler = ref.keydownHandler;
            var isShown = ref.isShown;
return _c('div',{},[_c('button',{staticClass:"mr-4 flex items-center transition duration-75 ease-in-out rounded-full text-black text-white text-xl focus:outline-none focus:shadow-solid",class:{ ' ': isShown },attrs:{"id":"notifications-menu","aria-label":"Notificaciones","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('icon',{attrs:{"icon":"bell"}}),(_vm.countNotificationsUnreaded)?_c('sup',{staticClass:"notification-indicator bg-red-50 text-red-700 border border-red-700 rounded-full -ml-2 -mt-2 font-bold text-xs",class:_vm.countNotificationsUnreaded.toString().includes('K')
                    ? 'notification-indicator-xl'
                    : ''},[_vm._v(_vm._s(_vm.countNotificationsUnreaded))]):_vm._e()],1)])}},{key:"default",fn:function(ref){
                    var hide = ref.hide;
return _c('div',{staticClass:"py-1 rounded-md shadow-xs"},[_c('Notifications',{attrs:{"hide":hide}})],1)}}])})],1),_c('div',[_c('t-dropdown',{attrs:{"text":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var mousedownHandler = ref.mousedownHandler;
            var focusHandler = ref.focusHandler;
            var blurHandler = ref.blurHandler;
            var keydownHandler = ref.keydownHandler;
            var isShown = ref.isShown;
return _c('div',{staticClass:"user-info flex flex-items-center flex-items"},[_c('div',{staticClass:"my-auto text-right leading-tight hidden sm:block",on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('p',{staticClass:"font-semibold text-white text-sm",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+" ")])]),_c('button',{staticClass:"ml-2 flex items-center transition duration-75 ease-in-out bg-gray-300 rounded-full focus:outline-none focus:shadow-solid",class:{ 'border-gray-300 bg-gray-500 text-white ': isShown },attrs:{"id":"user-menu","aria-label":"User menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('img',{staticClass:"w-12 h-12 rounded-full object-cover",attrs:{"src":_vm.user.image_profile
                    ? _vm.user.image_profile
                    : require('@/assets/img/profile/default.jpg'),"alt":""}})])])}}])},[_c('div',{staticClass:"py-1 rounded-md shadow-xs"},[_c('a',{staticClass:"block px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100",attrs:{"href":"#","role":"menuitem"},on:{"click":_vm.logout}},[_c('icon',{staticClass:"mr-2",attrs:{"icon":"sign-out-alt"}}),_vm._v(" Cerrar sesión ")],1)])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }