<template>
  <nav class="text-white font-bold" aria-label="Breadcrumb">
    <ol class="list-none p-0 inline-flex">
      <li
        class="inline-flex items-center text-white font-bold uppercase"
        v-if="route.meta.breadcrumbDynamic(route).length > 1"
      >
        <span class="text-primary cursor-pointer" @click="$router.go(-1)">
          <icon icon="chevron-left" class="text-white text-sm mr-2"></icon
        ></span>
      </li>

      <li
        v-for="(link, index) in route.meta.breadcrumbDynamic(route)"
        :key="index"
        class="inline-flex items-center text-white font-bold uppercase"
      >
        <router-link :to="link.url" v-if="link.url" class="text-xs">{{
          link.title
        }}</router-link>
        <span class="text-primary cursor-default" v-else>{{ link.title }}</span>
        <span
          v-if="route.meta.breadcrumbDynamic(route)[index + 1]"
          class="breadcrumb-separator mx-2 flex items-start text-white font-bold"
        >
          -
        </span>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "t-breadcrumb-dynamic",
  props: {
    route: {
      type: Object,
      required: true
    }
  }
}
</script>
