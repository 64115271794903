<template>
  <div>
    <h3 class="px-3 text-primary-800 font-bold py-2 border-b">
      Notificaciones
      <span
        class="text-sm text-primary-800 bg-primary-100 rounded-full px-2 float-right"
        >{{ countNotificationsUnreaded }}</span
      >
    </h3>
    <div
      v-if="!countNotificationsUnreaded"
      class="p-2 bg-primary-100 text-primary-800 m-2 rounded-md shadow-md"
    >
      <strong>¡Estás al día!</strong> No tienes notificaciones sin leer.
    </div>
    <div class="max-h-80 overflow-y-auto">
      <router-link v-for="item in notifications" :key="item.id" to="/">
        <button
          :class="`block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out bg-${
            !item.viewed ? configNotification(item.type_child).color : 'gray'
          }-50 hover:bg-gray-100 focus:outline-none focus:bg-gray-100`"
          role="menuitem"
          @click="viewNotification(item)"
          tag="button"
        >
          <div
            :class="`bg-white shadow-md rounded-md mr-2 p-2 justify-items-center flex text-${
              configNotification(item.type_child).color
            }-500 float-left`"
          >
            <icon :icon="configNotification(item.type_child).icon" />
          </div>
          <span :class="!item.viewed ? 'font-bold' : ''">{{ item.title }}</span>
          <br />
          <small>{{ item.description }}</small>
          <small class="float-right">{{
            new Date(item.created_at).setDate(
              new Date(item.created_at).getDate() - 1
            ) | transformDate("large")
          }}</small>
        </button>
      </router-link>
    </div>
    <div class="w-full p-1">
      <t-button
        class="w-full bg-primary-800"
        :disabled="!countNotificationsUnreaded"
        @click="viewAllNotification"
        >Marcar todas como leídas</t-button
      >
    </div>
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")
export default {
  props: ["hide"],
  data() {
    return {
      typeNotifications: {
        consult_treatment_review: {
          color: "yellow",
          icon: "star",
          url: ""
        },
        consult_treatment: {
          color: "purple",
          icon: "pills",
          url: ""
        },
        consult: {
          color: "green",
          icon: "file-medical",
          url: "/citas/atencion/"
        },
        consult_file: {
          color: "blue",
          icon: "file-contract",
          url: "/citas/atencion/"
        }
      }
    }
  },
  computed: {
    notifications() {
      return this.$store.getters["auth/getNotifications"]
    },
    countNotificationsUnreaded() {
      return this.$store.getters["auth/countNotificationsUnreaded"]
    }
  },
  methods: {
    configNotification(childId) {
      let config = this.typeNotifications[childId]
      if (!config) {
        config = {
          color: "primary",
          icon: "exclamation-triangle",
          url: ""
        }
      }
      return config
    },
    viewNotification(item) {
      this.$store
        .dispatch("auth/viewNotification", { id: item.id })
        .catch(() => {})
      this.$store.getters["auth/getNotifications"]
      this.hide()
    },
    viewAllNotification() {
      this.$store
        .dispatch("auth/viewAllNotification")
        .then(async (response) => {
          this.isLoading = false
          if ([200, 201].includes(response.code)) {
            this.$store.dispatch("auth/getNotifications")
          }
          notify().then(({ notification }) => {
            notification("notificationReaded", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  }
}
</script>
